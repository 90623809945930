/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { collection, onSnapshot, query, orderBy, doc, setDoc, deleteDoc } from 'firebase/firestore';
import { AldgQuickAdd, InputDate, InputTextarea } from '@aldridge/aldg-data-components';
import { useRecoilValue } from 'recoil';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { faCheck, faTimes, faTrash } from '@fortawesome/pro-regular-svg-icons';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import { Grid } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { _Clickstream, _CurrentJob, _MonthEndDate, _SecurityLevel } from '../../../_Recoil/atoms';
import { UserContext } from '../../../providers/UserProvider';
// import existsWithLength from '../../../utils/existsWithLength';
import { getPM, getOwner } from '../../../utils/getUserWithRole';
// import { getCurrentMEDate } from '../../../utils/monthEndOptions';
import { firebaseConfig, firestore } from '../../../firebase';
import markComplete from './ActionItemsFuncs';
import LinkToObj from '../Notes/LinkToObj';
import { AdminUp, DivisionalUp, EditorUp, SuperAdmin, ViewerUp } from '../UserAssignment/checkSecurity';
import existsWithLength from '../../../utils/existsWithLength';

const ActionItems = (props) => {
  const { jctdscid, memorialized } = props;
  const user = useContext(UserContext);
  const [actionItems, setActionItems] = useState([]);
  const Record = useRecoilValue(_CurrentJob);
  const [users, setUsers] = useState([]);
  const [Editing, setEditing] = useState(false);
  const MonthEndDate = useRecoilValue(_MonthEndDate);
  const Clickstream = useRecoilValue(_Clickstream);
  const SecurityLevel = useRecoilValue(_SecurityLevel);
  const apiRef = useGridApiRef();
  const [Loading, setLoading] = useState(true);
  const [currentRecord, setCurrentRecord] = useState({
    Name: '',
    Owner: '',
    PM: '',
    ActionItem: '',
    MonthEndAdded: '',
    DueDate: '',
    link: '',
    CompletionStatus: ''
  });
  const inAgenda = Clickstream.inAgenda;
  const canEdit = !inAgenda && !memorialized && EditorUp(SecurityLevel);

  const deleteDocument = async (row, id) => {
    await deleteDoc(doc(firestore, `ENT-Jobs/${jctdscid}/ActionItems`, id));
  };

  const isPMorCreator = (params) => [params.row.Creator, params.row.PM].includes(user.name) || user.admin;
  const columns = [
    {
      field: 'JobID',
      valueGetter: (params) => params.row.JobID,
      defaultValue: jctdscid,
      flex: 30
    },
    {
      field: 'Creator',
      valueGetter: (params) => params.row.Creator,
      defaultValue: user.name,
      flex: 30
    },
    {
      field: 'Owner',
      valueGetter: (params) => params.row.Owner,
      defaultValue: getOwner(users) || Record.DivisionManager || Record.VicePresident,
      flex: 30
    },
    {
      field: 'PM',
      valueGetter: (params) => params.row.PM || Record.ProjectManager,
      defaultValue: getPM(users) || Record.ProjectManager,
      flex: 30
    },
    {
      field: 'ActionItem',
      headerName: 'Action Item',
      valueGetter: (params) => params.row.ActionItem,
      canEdit: (params) => params.row.Creator === user.name && params.row.CompletionStatus !== 'Complete',
      inputType: 'InputTextarea',
      flex: 100
    },
    {
      field: 'MonthEndAdded',
      headerName: 'Month Added',
      valueGetter: (params) => params.row.MonthEndAdded || MonthEndDate,
      defaultValue: MonthEndDate,
      flex: 35
    },
    {
      field: 'DueDate',
      headerName: 'Due Date',
      valueGetter: (params) => params.row.DueDate,
      canEdit: (params) => params.row.Creator === user.name && params.row.CompletionStatus !== 'Complete',
      inputType: 'InputDate',
      flex: 35
    },
    LinkToObj,
    {
      field: 'Delete',
      headerName: '',
      renderCell: (params) => {
        if (params.row.ecmsRole !== true) {
          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <ToolTipIconButton
                shape='circle'
                tooltipText='Delete'
                color='error'
                icon={faTrash}
                onClick={() => {
                  const id = params.row.id;
                  deleteDocument(params.row, id);
                }}
              />
            </div>
          );
        }
        return null;
      },
      flex: 40
    },
    {
      field: 'CompletionStatus',
      headerName: 'Status',
      valueGetter: (params) => params.row.CompletionStatus,
      renderCell: (params) => {
        if (!Editing) {
          if (params.row.CompletionStatus === 'Incomplete')
            return (
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                Incomplete
                <ToolTipIconButton
                  shape='circle'
                  tooltipText='Mark complete'
                  color='success'
                  icon={faCheck}
                  onClick={() => markComplete(params, jctdscid, MonthEndDate)}
                />
              </div>
            );

          return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              Complete
              {isPMorCreator(params) && (
                <ToolTipIconButton
                  shape='circle'
                  tooltipText='Mark incomplete'
                  color='error'
                  icon={faTimes}
                  onClick={() => markComplete(params, jctdscid, MonthEndDate, 'Incomplete')}
                />
              )}
            </div>
          );
        }
        return `${params.row.CompletionStatus}`;
      },
      type: 'singleSelect',
      valueOptions: ['Incomplete', 'Complete'],
      defaultValue: 'Incomplete',
      flex: 40
    }
  ];
  const moreClasses = [{ '& .overdue': { fontWeight: 'bold', color: 'red' } }];

  useEffect(() => {
    apiRef?.current?.setColumnVisibilityModel({ JobID: false });
  }, []);

  useEffect(() => {
    let mounted = true;
    const aiq = query(collection(firestore, `ENT-Jobs/${jctdscid}/ActionItems`), orderBy('MonthEndAdded', 'desc'));
    onSnapshot(aiq, (snap) => {
      const all = [];
      snap.forEach((d) => all.push(d.data()));
      if (mounted) setActionItems(all.filter((a) => a.MonthEndAdded <= MonthEndDate));
    });
    const uq = query(collection(firestore, `ENT-Jobs/${jctdscid}/UserAssignment`));
    onSnapshot(uq, (snap) => {
      const all = [];
      snap.forEach((d) => all.push(d.data()));
      if (mounted) setUsers(all);
    });
    return () => {
      mounted = false;
    };
  }, [MonthEndDate, jctdscid]);

  const canUserViewNote = (_, rowWriterNote) => {
    if (SuperAdmin(SecurityLevel)) return true;
    if (AdminUp(SecurityLevel)) return !SuperAdmin(rowWriterNote);
    if (DivisionalUp(SecurityLevel)) return !AdminUp(rowWriterNote);
    if (ViewerUp(SecurityLevel)) return !DivisionalUp(rowWriterNote);
    return false;
  };

  const UpdateRow = (row) => {
    setCurrentRecord({
      JobID: row.JobID,
      Creator: users.name,
      Owner: row.Owner,
      PM: row.PM,
      ActionItem: row.ActionItem,
      MonthEndAdded: row.MonthEndAdded,
      DueDate: row.DueDate,
      link: row.link,
      CompletionStatus: row.CompletionStatus,
      id: row.id,
      CreatedDate: row.CreatedDate,
      CreatedBy: row.CreatedBy,
      ModifiedDate: new Date().toJSON(),
      ModifiedBy: user.email
    });
  };

  const handleSave = () => {
    const id = existsWithLength(currentRecord.id) ? currentRecord.id : Date.now().toString();
    setDoc(
      doc(firestore, `ENT-Jobs/${jctdscid}/ActionItems`, id),
      {
        JobID: jctdscid,
        Creator: user.name,
        Owner: getOwner(users) || Record.DivisionManager || Record.VicePresident,
        PM: getPM(users) || Record.ProjectManager,
        ActionItem: currentRecord.ActionItem,
        MonthEndAdded: MonthEndDate,
        DueDate: currentRecord.DueDate,
        link: '',
        CompletionStatus: 'Incomplete',
        id,
        CreatedDate: new Date().toJSON(),
        CreatedBy: user.email,
        ModifiedDate: new Date().toJSON(),
        ModifiedBy: user.email
      },
      { merge: true }
    );
    setCurrentRecord({
      Name: '',
      Owner: '',
      PM: '',
      ActionItem: '',
      MonthEndAdded: '',
      DueDate: '',
      link: '',
      CompletionStatus: '',
      id: ''
    });
  };

  const handleCancel = () => {
    setCurrentRecord({
      Name: '',
      Owner: '',
      PM: '',
      ActionItem: '',
      MonthEndAdded: '',
      DueDate: '',
      link: '',
      CompletionStatus: '',
      id: ''
    });
  };

  return (
    <Grid container justifyContent='center'>
      <AldgQuickAdd
        DataGridProps={{
          apiRef,
          pagination: true,
          getRowID: (params) => params.row.id || Date.now().toString(),
          onCellClick: (cell, event) => {
            if (cell.field === 'Delete') {
              event.preventDefault();
              event.stopPropagation();
            }
          }
        }}
        anchor='right'
        updateRow={UpdateRow}
        columns={columns}
        rows={actionItems}
        handleSave={handleSave}
        handleCancel={handleCancel}
        form={
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} padding={1}>
            <Grid item xs={12}>
              <InputTextarea
                fullWidth
                label='Action Item'
                value={currentRecord.ActionItem || ''}
                onChange={(e) => {
                  setCurrentRecord({ ...currentRecord, ActionItem: e });
                }}
                id='fullWidth'
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <InputDate
                  fullWidth
                  label='Due Date'
                  value={currentRecord.DueDate || ''}
                  onChange={(e) => setCurrentRecord({ ...currentRecord, DueDate: e })}
                  id='fullWidth'
                  slotProps={{
                    popper: { sx: { zIndex: 200000 } }
                  }}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>
        }
      />
    </Grid>
  );
};

ActionItems.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  memorialized: PropTypes.bool
};
ActionItems.defaultProps = {
  memorialized: false
};

export default ActionItems;
