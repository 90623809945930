/* eslint-disable no-unused-vars */
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Paper, Typography, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import { getDoc, setDoc, doc, collection } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { faFileDownload, faSave, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { UserContext } from '../../../providers/UserProvider';
import InputToggle from '../../UIComponents/InputToggle';
import InputToggleNumber from '../../UIComponents/InputToggleNumber';
import { firestore } from '../../../firebase';
import TooltipIconButton from '../../UIComponents/TooltipIconButton';
import DownloadAbstractTemplate from './DownloadAbstractTemplate';

const ContractAbstract = (props) => {
  const { Transaction, jctdscid, abstractId, history } = props;
  const user = useContext(UserContext);
  const [abstractRecord, setAbstractRecord] = React.useState({
    id: 'new',
    CreatedDate: new Date(),
    CreatedBy: user.email || 'No email',
    ModifiedDate: '',
    ModifiedBy: '',
    data: {
      Contact: '',
      CustomerPhoneNumber: '',
      Email: '',
      ContractDescription: '',
      Risk: ['', '', ''],
      Opportunities: ['', '', ''],
      ConsequentialDamagesAllowed: '',
      HowPaidForChanges: '',
      HasLiquidatedDamages: '',
      AdministrativeRequirements: {
        SafetyPlanRequired: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        QualityPlanRequired: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        HaveDBEPlanCommitments: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        OwnerApproveSubs: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        ResponsibleForPermits: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        OrderOfPrecidence: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' }
      },
      ScheduleItems: {
        ProjectStartDate: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        MilestonesDatesListed: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        ProjectCompletionDateListed: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        SubstantialCompletionDefined: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        OwnerAcceptWork: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        CPMScheduleRequired: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        JobHasLiquatedDamages: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' }
      },
      PaymentTerms: {
        FlowChartPaymentProcess: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        OftenTeamBill: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        WhenPaid: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        PayAppDueDate: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        CertPayrollRequired: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        RetentionHeld: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        ProvideFinalInvoice: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' }
      },
      Submittals: {
        SubmittalsRequired: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        LengthOwnerReview: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        ProvideCustomerDeliverableLog: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' }
      },
      DelaysAndDamages: {
        PaidForDelays: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        CanAddDaysForDelays: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        NotifyCustomerTime: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        ChangeOrderProcess: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        CustomerAccelerateSchedule: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        EncounterUnforeseenCondition: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        ReferenceSubsurfaceConditions: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        ConsequentialDamagesListed: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' },
        PaidForWeatherDays: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' }
      },
      Warranty: {
        ProjectWarranty: { ContractDeadline: '', ContractSection: '', SubSection: '', Notes: '' }
      }
    }
  });
  const tables = [
    {
      name: { title: 'Administrative Requirements', header: 'AdministrativeRequirements' },
      description: [
        { question: 'Is a Safety Plan Required?', header: 'SafetyPlanRequired' },
        { question: 'Is a Quality Plan Required?', header: 'QualityPlanRequired' },
        { question: 'Do we have DBE Plan Commitments?', header: 'HaveDBEPlanCommitments' },
        { question: 'Does The Owner have to approve Subs', header: 'OwnerApproveSubs' },
        { question: 'Who is responsible for permits?', header: 'ResponsibleForPermits' },
        { question: 'What is the order of precidence for docs?', header: 'OrderOfPrecidence' }
      ]
    },
    {
      name: { title: 'Schedule Items', header: 'ScheduleItems' },
      description: [
        { question: 'Project Start Date', header: 'ProjectStartDate' },
        {
          question: 'Are Milestones Dates Listed?',
          header: 'MilestonesDatesListed'
        },
        {
          question: 'Is a Project Completion Date Listed?',
          header: 'ProjectCompletionDateListed'
        },
        {
          question: 'How is Substantial Completion Defined?',
          header: 'SubstantialCompletionDefined'
        },
        {
          question: 'How does the Owner Accept our Work?',
          header: 'OwnerAcceptWork'
        },
        {
          question: 'Is a CPM Schedule Required?',
          header: 'CPMScheduleRequired'
        },
        {
          question: 'Does the Job have Liquated Damages?',
          header: 'JobHasLiquatedDamages'
        }
      ]
    },
    {
      name: { title: 'Payment Terms', header: 'PaymentTerms' },
      description: [
        { question: 'Create Flow Chart for Payment Process', header: 'FlowChartPaymentProcess' },
        { question: 'How Often Can Team Bill?', header: 'OftenTeamBill' },
        { question: 'When Will We Get Paid? (Paid when paid?)', header: 'WhenPaid' },
        { question: 'What Date Does Our Pay App Need to be in By?', header: 'PayAppDueDate' },
        { question: 'Is Cert Payroll Required?', header: 'CertPayrollRequired' },
        { question: 'Is Retention Going to be Held?', header: 'RetentionHeld' },
        { question: 'How do we Provide a Final invoice?', header: 'ProvideFinalInvoice' }
      ]
    },
    {
      name: { title: 'Submittals', header: 'Submittals' },
      description: [
        { question: 'What Submittals are Required?', header: 'SubmittalsRequired' },
        { question: 'How long does Owner have to Review?', header: 'LengthOwnerReview' },
        { question: 'Do we have to provide Customer a Deliverable Log?', header: 'ProvideCustomerDeliverableLog' }
      ]
    },
    {
      name: { title: 'Delays and Damages', header: 'DelaysAndDamages' },
      description: [
        { question: 'Are we able to be Paid for Delays?', header: 'PaidForDelays' },
        { question: 'Are we able to have days added to our Schedule for Delays?', header: 'CanAddDaysForDelays' },
        { question: 'How Quickly do we Need to Notify the Customer?', header: 'NotifyCustomerTime' },
        { question: 'Map out the Change Order Process', header: 'ChangeOrderProcess' },
        { question: 'Can the Customer Accelerate our Schedule?', header: 'CustomerAccelerateSchedule' },
        { question: 'What happens when we encounter an Unforeseen Condition?', header: 'EncounterUnforeseenCondition' },
        { question: 'Does the Contract Reference Subsurface conditions?', header: 'ReferenceSubsurfaceConditions' },
        { question: 'Are Consequential Damages Listed?', header: 'ConsequentialDamagesListed' },
        { question: 'Can we be paid or have our scheduled increased due to Weather days?', header: 'PaidForWeatherDays' }
      ]
    },
    { name: { title: 'Warranty', header: 'Warranty' }, description: [{ question: 'What is the Project Warranty?', header: 'ProjectWarranty' }] }
  ];

  useEffect(() => {
    let mounted = true;
    if (abstractId !== 'new' && mounted) {
      getDoc(doc(firestore, `ENT-Jobs/${jctdscid}/ContractAbstract`, abstractId)).then((d) => {
        if (!d.exists() && mounted) {
          toast.error('This contract abstract does not exist in this job or has been deleted. Select a different contract abstract.');
          history.push(`/Job/${jctdscid}/ContractAbstract`);
        } else if (mounted) setAbstractRecord((PREV) => ({ ...PREV, ...d.data() }));
      });
    }
    return () => {
      mounted = false;
    };
  }, [abstractId]);

  const onChange = (value, name) => {
    const newRecord = { ...abstractRecord };
    if (abstractRecord.id === 'new') {
      newRecord.CreatedDate = new Date();
      newRecord.CreatedBy = user.email || 'No email';
    }

    if (name.includes('Risk') || name.includes('Opportunities')) {
      const type = name.split('-')[0];
      const index = parseInt(name.split('-')[1], 10);
      newRecord.data[type][index] = value;
    } else if (
      name.includes('AdministrativeRequirements') ||
      name.includes('ScheduleItems') ||
      name.includes('PaymentTerms') ||
      name.includes('Submittals') ||
      name.includes('DelaysAndDamages') ||
      name.includes('Warranty')
    ) {
      const type = name.split('-')[0];
      const index = name.split('-')[1];
      const subIndex = name.split('-')[2];
      newRecord.data[type][index][subIndex] = value;
    } else {
      newRecord.data[name] = value;
    }
    newRecord.ModifiedDate = new Date();
    newRecord.ModifiedBy = user.email || 'No email';
    console.log('updated', newRecord);
    setAbstractRecord(newRecord);
  };

  const handleSave = async () => {
    if (abstractRecord.id === 'new') abstractRecord.id = doc(collection(firestore, `ENT-Jobs/${jctdscid}/ContractAbstract`)).id;
    const recordId = abstractRecord.id;

    const dRef = doc(firestore, `ENT-Jobs/${jctdscid}/ContractAbstract`, recordId);
    setDoc(dRef, JSON.parse(JSON.stringify(abstractRecord)), { merge: true });
    toast.success('Contract Abstract has been saved. If you are finished, you may close the record.');
  };

  return (
    <Paper variant='outlined' className='aldg-field'>
      <Grid container>
        <Grid
          item
          xs={12}
          style={{
            display: 'contents',
            padding: '0px',
            textAlign: 'center'
          }}
        >
          <div
            style={{
              position: 'sticky',
              top: '-1px',
              left: 0,
              right: 0,
              width: '100%',
              padding: '8px',
              display: 'flex',
              justifyContent: 'flex-end',
              zIndex: 100,
              backgroundColor: 'white'
            }}
          >
            <TooltipIconButton onClick={() => handleSave(false)} color='success' title='Save' icon={faSave} />
            <TooltipIconButton
              color='gray'
              onClick={() => {
                history.push(`/Job/${jctdscid}/ContractAbstract`);
              }}
              icon={faTimes}
              title='Cancel'
            />
            <TooltipIconButton
              color='default'
              onClick={() => DownloadAbstractTemplate(Transaction, abstractRecord, jctdscid, tables)}
              icon={faFileDownload}
              title='Download Template'
            />
          </div>
        </Grid>

        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h5'> Aldridge Contract Abstract </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputToggleNumber edit={false} name='AldridgeJobNumber' value={Transaction.id} label='Aldridge Job Number' />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit={false} name='Owner' value={Transaction.CosentialOwner} label='Owner' />
        </Grid>
        {/* <Grid item xs={12}>
          <InputToggle edit name='Contractor' value='' label='Contractor' />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Subcontractor' value='' label='Subcontractor' />
        </Grid> */}
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h5'> Contact Information </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Contact' onChange={onChange} value={abstractRecord.data.Contact || ''} label='Contact' />
        </Grid>
        <Grid item xs={12}>
          <InputToggleNumber
            edit
            name='CustomerPhoneNumber'
            onChange={onChange}
            value={abstractRecord.data.CustomerPhoneNumber || ''}
            label="Customer's Phone #"
          />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Email' onChange={onChange} value={abstractRecord.data.Email || ''} label='Email' />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h5'> Contract Description </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputToggle
            edit
            name='ContractDescription'
            onChange={onChange}
            value={abstractRecord.data.ContractDescription || ''}
            label='Contract Description (What are they paying us for)'
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h5'> Top 3 Risks </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Risk-0' onChange={onChange} value={abstractRecord.data.Risk[0] || ''} label='1' />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Risk-1' onChange={onChange} value={abstractRecord.data.Risk[1] || ''} label='2' />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Risk-2' onChange={onChange} value={abstractRecord.data.Risk[2] || ''} label='3' />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h5'> Top 3 Opportunities </Typography>
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Opportunities-0' onChange={onChange} value={abstractRecord.data.Opportunities[0] || ''} label='1' />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Opportunities-1' onChange={onChange} value={abstractRecord.data.Opportunities[1] || ''} label='2' />
        </Grid>
        <Grid item xs={12}>
          <InputToggle edit name='Opportunities-2' onChange={onChange} value={abstractRecord.data.Opportunities[2] || ''} label='3' />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant='h5'> Major Contract Priorities </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid item xs={12}>
            <InputToggle
              edit
              name='ConsequentialDamagesAllowed'
              onChange={onChange}
              value={abstractRecord.data.ConsequentialDamagesAllowed || ''}
              label='Are Consequential Damages allowed'
            />
          </Grid>
          <Grid item xs={12}>
            <InputToggle
              edit
              name='HowPaidForChanges'
              onChange={onChange}
              value={abstractRecord.data.HowPaidForChanges || ''}
              label='How are we being paid for changes or impacts'
            />
          </Grid>
          <Grid item xs={12}>
            <InputToggle
              edit
              name='HasLiquidatedDamages'
              onChange={onChange}
              value={abstractRecord.data.HasLiquidatedDamages || ''}
              label='Does the contract have Liquidated Damages?'
            />
          </Grid>
          <Grid item xs={12}>
            {tables.map((rows) => (
              <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontSize: 24 }} align='center'>
                      {rows.name.title}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell> Description </TableCell>
                    <TableCell align='left'> Contract Deadline </TableCell>
                    <TableCell align='left'> Contract Section </TableCell>
                    <TableCell align='left'> Sub Section </TableCell>
                    <TableCell align='left'> Notes </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.description.map((row) => (
                    <TableRow key={row.header}>
                      <TableCell component='th' scope='row'>
                        {row.question}
                      </TableCell>
                      <TableCell align='left'>
                        <InputToggle
                          edit
                          name={`${rows.name.header}-${row.header}-ContractDeadline`}
                          onChange={onChange}
                          value={abstractRecord.data[rows.name.header][row.header].ContractDeadline}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <InputToggle
                          edit
                          name={`${rows.name.header}-${row.header}-ContractSection`}
                          onChange={onChange}
                          value={abstractRecord.data[rows.name.header][row.header].ContractSection}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <InputToggle
                          edit
                          name={`${rows.name.header}-${row.header}-SubSection`}
                          onChange={onChange}
                          value={abstractRecord.data[rows.name.header][row.header].SubSection}
                        />
                      </TableCell>
                      <TableCell align='left'>
                        <InputToggle
                          edit
                          name={`${rows.name.header}-${row.header}-Notes`}
                          onChange={onChange}
                          value={abstractRecord.data[rows.name.header][row.header].Notes}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};

ContractAbstract.propTypes = {
  Transaction: PropTypes.object.isRequired,
  jctdscid: PropTypes.string.isRequired,
  abstractId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};
ContractAbstract.defaultProps = {};

export default ContractAbstract;
