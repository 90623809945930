import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { collection, doc, getDocs, query, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { firestore } from '../../../firebase';

const pullLastCashFlow = async (jctdscid, monthEndDate, lastMonth, getRows, showToast = true) => {
  dayjs.extend(isSameOrBefore);
  const q = query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${lastMonth}/CashFlow`));
  getDocs(q).then((d) => {
    const lastCashFlow = [];
    d.forEach((dd) => lastCashFlow.push(dd.data()));
    lastCashFlow.forEach((cf) => {
      const month = dayjs(cf.Month);
      let fixedMonth = dayjs();
      fixedMonth = fixedMonth.month(`${month.month()}`);
      fixedMonth = fixedMonth.year(`${`20${month.date()}`}`);
      fixedMonth = fixedMonth.date('1');
      const finalMonth = fixedMonth.endOf('month');
      const isActual = finalMonth.isSameOrBefore(dayjs(monthEndDate).endOf('month'));
      if (!isActual) {
        const docRef = doc(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/CashFlow`), cf.Month);
        setDoc(docRef, cf);
      }
    });
    if (showToast) toast.success(`Cash flow successfully pulled ${NaNtoZero(lastCashFlow.length)} months!`);
    getRows();
  });
};

export default pullLastCashFlow;
