import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { faArrowRightArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import { Grid, Paper, Typography } from '@mui/material';
import { ToolTipIconButton } from '@aldridge/aldg-ui-components';
import { collection, query, onSnapshot, where, doc, setDoc } from 'firebase/firestore';
import { AldgDataGrid } from '@aldridge/aldg-data-components';
import { useGridApiRef } from '@mui/x-data-grid-premium';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config } from '@fortawesome/fontawesome-svg-core';
import { firestore } from '../../../firebase';
import { formatAsCurrency } from '../../UIComponents/format';

const CostCodes = (props) => {
  const { jctdscid, monthEndDate } = props;
  const [costCodes, setCostCodes] = useState([]);
  const [changedCCs, setChangedCCs] = useState([]);

  const apiRef1 = useGridApiRef();
  const apiRef2 = useGridApiRef();
  const mounted = useRef(null);

  const active = [];
  const inactive = [];

  const miniIcon = () => <FontAwesomeIcon icon={faArrowRightArrowLeft} />;

  useEffect(() => {
    mounted.current = true;
    const fRef = query(collection(firestore, `ENT-Piccs`), where('jobid', '==', jctdscid));
    onSnapshot(fRef, (snap) => {
      const allCostCodes = [];

      snap.forEach((d) => allCostCodes.push(d.data()));
      if (mounted) setCostCodes(allCostCodes.filter((cc) => cc.CostType !== 'B'));
    });
    return () => {
      mounted.current = false;
    };
  }, [monthEndDate]);

  useEffect(() => {
    mounted.current = true;
    const cRef = query(collection(firestore, 'PiccChanges'), where('jobid', '==', jctdscid));
    onSnapshot(cRef, (snap) => {
      const changedCostCodes = [];

      snap.forEach((d) => changedCostCodes.push(d.data()));
      setChangedCCs(changedCostCodes);
    });
    return () => {
      mounted.current = false;
    };
  }, [monthEndDate]);

  const columns = [
    {
      field: 'id',
      headerAlign: 'center',
      headerName: 'ID',
      flex: 1,
      valueGetter: (params) => params.row.jctmstid || ''
    },
    {
      field: 'PICC',
      headerAlign: 'center',
      headerName: 'PICC',
      valueGetter: (params) => params.row.PICC || ''
    },
    {
      field: 'Description',
      headerAlign: 'center',
      headerName: 'Description',
      flex: 1,
      valueGetter: (params) => params.row.Description || ''
    },
    {
      field: 'FriendlyName',
      headerAlign: 'center',
      headerName: 'Supplementary Description',
      flex: 1,
      valueGetter: (params) => params.row.FriendlyName || '',
      canEdit: true,
      inputType: 'InputText'
    },
    {
      field: 'CostToDate',
      headerAlign: 'center',
      headerName: 'Cost To Date',
      flex: 1,
      valueGetter: (params) => formatAsCurrency(params.row.CostToDate) || 0
    },
    {
      field: 'HoursToDate',
      headerAlign: 'center',
      headerName: 'Hours To Date',
      flex: 1,
      valueGetter: (params) => params.row.HoursToDate || 0
    },
    {
      field: 'Status',
      headerAlign: 'center',
      headerName: 'Status',
      flex: 1,
      valueGetter: (params) => params.row.Status || ''
    },
    {
      field: 'DateUpdated',
      headerAlign: 'center',
      headerName: 'Date Updated',
      flex: 1,
      valueGetter: (params) => dayjs(params.row.DateUpdated).format('MM/DD/YYYY') || ''
    }
  ];

  const uCostCodes = JSON.parse(JSON.stringify(costCodes));
  // set status field to active/inactive on the button click
  const MoveSelected = async () => {
    if (apiRef1.current) {
      const selectedRows = Array.from(apiRef1.current.getSelectedRows(), ([key, value]) => ({ key, value }));

      for (let i = 0; i < selectedRows.length; i++) {
        const r = selectedRows[i];
        if (r.value.Status === 'Active') {
          r.value.Status = 'Inactive';
        }
        const docRef = doc(firestore, 'PiccChanges', r.value.jctmstid);
        // eslint-disable-next-line no-await-in-loop
        await setDoc(docRef, r.value);
      }

      if (selectedRows.length > 0) toast.success(`Successfully closed ${selectedRows.length} cost code(s).`);
    }
    if (apiRef2.current) {
      const selectedRows = Array.from(apiRef2.current.getSelectedRows(), ([key, value]) => ({ key, value }));
      for (let i = 0; i < selectedRows.length; i++) {
        const r = selectedRows[i];
        if (r.value.Status === 'Inactive') {
          r.value.Status = 'Active';
        }
        const docRef = doc(firestore, 'PiccChanges', r.value.jctmstid);
        // eslint-disable-next-line no-await-in-loop
        await setDoc(docRef, r.value);
      }
      if (selectedRows.length > 0) toast.success(`Successfully opened ${selectedRows.length} cost code(s).`);
    }
    setCostCodes(uCostCodes);
  };

  uCostCodes.forEach((ucc) => {
    if (ucc?.Status === 'Active') active.push(ucc);
    if (ucc?.Status === 'Inactive') inactive.push(ucc);
  });

  changedCCs.forEach((ccc) => {
    if (ccc?.Status === 'Active') active.push(ccc);
    if (ccc?.Status === 'Inactive') inactive.push(ccc);
  });

  const uActive = Array.from(new Set(uCostCodes));
  // const uInactive = Array.from(new Set(inactive));
  return (
    <>
      <Typography style={{ fontStyle: 'italic', alignContent: 'right' }}>
        View lists of open and closed cost codes. Select the codes you would like to set to open or closed and use the {miniIcon()} button to make the
        Status change.
      </Typography>
      <Paper variant='outlined' className='aldg-field'>
        <Grid container columns={21}>
          <Grid item xs={21}>
            <h2>Open Cost Codes</h2>

            <AldgDataGrid
              checkboxSelection
              apiRef={apiRef1}
              columns={columns}
              totalRow
              rows={uActive}
              getRowId={(row) => row.jctmstid}
              containerHeight={costCodes.length >= 8 ? '600px' : undefined}
              canDelete={false}
              canAdd={false}
              editable
              ToolbarProps={{ canDelete: false, canAdd: false }}
              FirebaseProps={{
                firebaseConfig: config,
                collection: `ENT-Piccs`,
                valueKey: 'jctmstid'
              }}
            />
          </Grid>
          <Grid item xs={1} alignContent='left'>
            <ToolTipIconButton
              style={{ width: 40 }}
              onClick={() => MoveSelected()}
              icon={faArrowRightArrowLeft}
              tooltipText='Change Cost Code Status'
            />
          </Grid>
          {/* <Grid item xs={10} style={{ paddingLeft: 0 }}>
            <h2>Closed Cost Codes</h2>

            <AldgDataGrid
              checkboxSelection
              apiRef={apiRef2}
              columns={columns}
              totalRow
              rows={uInactive}
              getRowId={(row) => row.RN}
              containerHeight={costCodes.length >= 8 ? '600px' : undefined}
            />
          </Grid> */}
        </Grid>
      </Paper>
    </>
  );
};
CostCodes.propTypes = {
  jctdscid: PropTypes.string.isRequired,
  // eslint-disable-next-line react/require-default-props
  monthEndDate: PropTypes.string
};
CostCodes.defaultProps = {};
export default CostCodes;
