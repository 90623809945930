import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, useMediaQuery } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { AldgTheme, nonMuiThemes } from '../../../theme';
import { StatusContext } from '../../../providers/StatusProvider';
import ProjectOverview from './ProjectOverview';
import ContractDetails from './ContractDetails';
import AccountingSummaryME from './AccountingSummaryME';
import AccountingSummaryTD from './AccountingSummaryTD';
import CostDetails from './CostDetails';
import LaborSummary from './LaborSummary';
import GrossProfitReports from './GrossProfitReports';
import EquipmentRates from './EquipmentRates';
import { getOgBudget, getProjectedTotal } from './getValues';
import MoMandHistory from './MoMandHist';
import { _SecurityLevel } from '../../../_Recoil/atoms';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { SumCostDetails } from './calculationHelpers';

const ProjectStatus = (props) => {
  const { Transaction, monthEndDate } = props;
  const { budgets, forecasting, contractValues } = useContext(StatusContext);

  const Role = useRecoilValue(_SecurityLevel);
  const ogBudget = getOgBudget(structuredClone(budgets));

  const matches = useMediaQuery(AldgTheme.breakpoints.up('lg'));
  const matchesMd = useMediaQuery(AldgTheme.breakpoints.up('md'));

  const totalPercentComplete = NaNtoZero(
    SumCostDetails(structuredClone(forecasting), undefined, 'CostToDate') / getProjectedTotal(structuredClone(forecasting))
  );

  return (
    <Grid container className='aldg-gridNoPadding'>
      <Grid item xs={12} className={!matches ? 'aldg-gridNoPadding' : ''}>
        <ProjectOverview Transaction={Transaction} monthEndDate={monthEndDate} />
      </Grid>
      {!matches && <div style={nonMuiThemes.spacer} />}
      <Grid item xs={12} lg={7} className={!matches ? 'aldg-gridNoPadding' : ''} style={{ padding: matches && '8px 4px 0px 4px' }}>
        <ContractDetails Transaction={Transaction} MonthEndContractNumbers={contractValues} />
      </Grid>
      {!matchesMd && <div style={nonMuiThemes.spacer} />}
      <Grid item xs={12} md={6} lg={2.5} style={{ padding: matches ? '8px 4px 0px 4px' : '16px 4px 0px 1px' }}>
        <AccountingSummaryME jctdscid={Transaction.id} />
      </Grid>
      <Grid item xs={12} md={6} lg={2.5} style={{ padding: matches ? '8px 4px 0px 4px' : '16px 1px 0px 4px' }}>
        <AccountingSummaryTD
          jctdscid={Transaction.id}
          Transaction={Transaction}
          changeOrders={structuredClone(budgets)}
          MonthEndContractNumbers={contractValues}
          totalPercentComplete={totalPercentComplete}
        />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <CostDetails
          jctdscid={Transaction.id}
          changeOrders={structuredClone(budgets)}
          forecast={structuredClone(forecasting)}
          MonthEndContractNumbers={contractValues}
        />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <LaborSummary changeOrders={structuredClone(budgets)} forecast={structuredClone(forecasting)} Role={Role} />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <EquipmentRates changeOrders={structuredClone(budgets)} forecast={structuredClone(forecasting)} Role={Role} />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <GrossProfitReports
          Transaction={Transaction}
          changeOrders={structuredClone(budgets)}
          forecast={structuredClone(forecasting)}
          MonthEndContractNumbers={contractValues}
          Role={Role}
        />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <MoMandHistory jctdscid={Transaction.id} ogBudget={ogBudget} Role={Role} />
      </Grid>
      <div style={nonMuiThemes.spacer} />
      <Grid item xs={12} className='aldg-gridNoPadding'>
        <MoMandHistory jctdscid={Transaction.id} ogBudget={ogBudget} wPending Role={Role} />
      </Grid>
    </Grid>
  );
};

ProjectStatus.propTypes = {
  Transaction: PropTypes.object,
  monthEndDate: PropTypes.string
};
ProjectStatus.defaultProps = {
  Transaction: {},
  monthEndDate: '2010-12-31'
};

export default ProjectStatus;
