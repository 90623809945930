const existsWithLength = (val) => {
  if (val === null) return false;
  switch (typeof val) {
    case 'undefined':
      return false;
    case 'string':
      return val.length > 0;
    case 'object': {
      if (Array.isArray(val)) return val.length > 0;
      return true;
    }
    case 'number':
      return !Number.isNaN(val);
    case 'function':
    case 'symbol':
    case 'bigint':
    case 'boolean':
      return true;
    default:
      return false;
  }
};

export default existsWithLength;
