// eslint-disable-next-line no-unused-vars
import { collection, doc, getDocs, orderBy, query, setDoc, where, writeBatch } from 'firebase/firestore';
import { toast } from 'react-toastify';
import NaNtoZero from '../../../utils/convertNaNToZero';
import { firestore } from '../../../firebase';

const pullPreviousBudgets = async (jctdscid, monthEndDate, monthToPullFrom, user, placeCalled, showToast = true) =>
  getDocs(query(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthToPullFrom}/ChangeOrders`))).then(async (docs) => {
    if (monthToPullFrom !== monthEndDate) {
      const newBudgets = [];
      const currentBudgets = [];

      const currentBudgetDocs = await getDocs(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/ChangeOrders`));
      currentBudgetDocs.forEach((a) => currentBudgets.push(a.data()));

      const batch = writeBatch(firestore);
      docs.forEach((d) => {
        const prevBudget = d.data();
        const existingBudget = currentBudgets.filter((curr) => curr.prevRecordID === prevBudget.id);
        if (existingBudget.length === 0) {
          const newId = doc(collection(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/ChangeOrders`)).id;
          const generatedDoc = JSON.parse(JSON.stringify(prevBudget));

          generatedDoc.id = newId;
          generatedDoc.datePulled = new Date().toJSON();
          generatedDoc.pulledBy = user.email;
          generatedDoc.placeCalled = showToast ? placeCalled : 'Jobs to Status';
          generatedDoc.prevRecordID = prevBudget.id;
          generatedDoc.data.JobID = jctdscid;
          generatedDoc.data.MonthEndDate = monthEndDate;
          generatedDoc.MonthPulledFrom = monthToPullFrom;

          newBudgets.push(generatedDoc);

          // LOOKHERE
          batch.set(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/ChangeOrders`, newId), generatedDoc, { merge: true });
        } else {
          const generatedDoc = JSON.parse(JSON.stringify(prevBudget));
          generatedDoc.updatePulled = new Date().toJSON();
          generatedDoc.prevRecordID = prevBudget.id;
          generatedDoc.MonthPulledFrom = monthToPullFrom;
          generatedDoc.id = existingBudget[0].id;
          // LOOKHERE
          batch.set(doc(firestore, `ENT-Jobs/${jctdscid}/MonthEnd/${monthEndDate}/ChangeOrders`, existingBudget[0].id), generatedDoc, {
            merge: true
          });
        }
      });

      await batch.commit();

      if (newBudgets.length > 0) {
        if (showToast) toast.success(`Successfully pulled ${NaNtoZero(newBudgets.length)} budgets!`);
        console.log(`Successfully pulled ${NaNtoZero(newBudgets.length)} budgets!`);
      } else {
        if (showToast) toast.error('No new budgets to pull!');
        console.log('No new budgets to pull!');
      }

      return newBudgets;
    }
    if (showToast) {
      toast.error('You cannot pull budgets from the current month!');
      return [];
    }
    return [];
  });

export default pullPreviousBudgets;
